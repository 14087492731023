<template>
  <div>
    <v-row>
      <SuccessAlert
        data-cy="alert"
        class="alert"
        v-if="showAlert"
        :type="alertType"
        :message="alertMessage"
      />
    </v-row>
    <!-- Structures Table -->
    <v-data-table
      dense
      :search="search"
      class="px-6 mt-5"
      loading-text="loading..."
      no-data-text="no structure available"
      :headers="headers"
      :items="getTableData"
      :items-per-page="10"
      :loading="loading"
      :single-expand="singleExpand"
      item-key="_uuid"
      show-expand
      :expanded.sync="expanded"
    >
    <!-- inherent table search functionality -->
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <!-- convert createdDate -->
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ new Date(item.createdDate).toLocaleString() }}</span>
      </template>

      <!-- table actions -->
      <!-- copy to clipboard -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="copy"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="copyToClipboard(item)"
              x-small
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copy Structure to Clipboard</span>
        </v-tooltip>
        <!-- load to ui for editing -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="load_to_ui"
              color="primary"
              @click.native="loadToUI(item)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
            >
              <v-icon>mdi-weather-cloudy-arrow-right</v-icon></v-btn
            >
          </template>
          <span>Load Structure for re-configuration</span>
        </v-tooltip>
        <!-- configuration info -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="show_info"
              color="primary"
              @click="info(item)"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
            >
              <v-icon>mdi-clipboard-text-outline</v-icon></v-btn
            >
          </template>
          <span>Show Structure Configuration & Info</span>
        </v-tooltip>
        <!-- delete item -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="delete_structure"
              color="primary"
              v-bind="attrs"
              v-on="on"
              icon
              x-small
              @click="askConfirmation(item)"
            >
              <v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <!-- foldable description -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">Description: {{ item.description }}</td>
      </template>
    </v-data-table>
    <ConfigurationInfo ref="info" :structure="structure" :hasTabs="hasTabs" />

    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteDataItem"
    />
  </div>
</template>

<script>
const ConfirmDialog = () => import('./general/ConfirmDialog')
const SuccessAlert = () => import('./general/SuccessAlert.vue')
const ConfigurationInfo = () => import('./general/ConfigurationInfo.vue')
import utils from '../appUtils/utils.js'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    SuccessAlert,
    ConfigurationInfo,
    ConfirmDialog,
  },

  data() {
    return {
      /* bulk delete functionality of table */
      itemToDelete: {},
      showConfirmDialog: false,
      /* table search */
      search: '',
      /* table row expand */
      expanded: [],
      singleExpand: false,
      /* SuccessAlert */
      showAlert: false,
      alertType: '',
      alertMessage: '',
      /* ConfigurationInfo */
      title: 'Configuration',
      copyData: {},
      hasTabs: false,
      structure: {},
      /* UI mechanics */
      loading: false,
    }
  },

  methods: {
    ...mapMutations('backend', { setBackendStore: 'set' }),

    ...mapMutations('dynamicForms', ['createUserInputFromDataNode', 'set']),

    ...mapActions('backend', ['delete', 'load', 'loadMultipleDataSets']),

    async loadToUI(item) {
      this.loading = true
      this.set(['edit', true])
      let data = await this.getById(['data', item._uuid])
      this.setBackendStore(['data', data])
      await this.createUserInputFromDataNode(data)
      this.$router.push('/structures/' + utils.convertToPathNotation(data.hqschema.label))
      this.loading = false
    },

    copyToClipboard(item) {
      this.showAlert = false
      this.copyData = this.getById(['data', item._uuid])
      try {
        navigator.clipboard.writeText(JSON.stringify(this.copyData, null, 2))
        this.prepareAlert('Copied successfully!', 'success', this.alertTimeout)
      } catch (error) {
        this.prepareAlert('Copying failed!', 'error')
      }
      this.copyData = {}
    },

    askConfirmation(item) {
      this.itemToDelete = item
      this.showConfirmDialog = true
    },

    async deleteDataItem() {
      try {
        this.showConfirmDialog = false
        await this.delete(['data', this.itemToDelete._uuid])
        this.prepareAlert('Deleted successfully!', 'success')
        if (this.$route.name.includes('all')) {
            await this.load(['data'])
        } else {
          let schemas = this.schemas.filter(
            (e) => e.label === this.schemaLabelFromRouteName
          )
          await this.loadMultipleDataSets(schemas)
        }
      } catch (error) {
        console.log('error', error)
        this.showConfirmDialog = false
        this.prepareAlert('Deleting your structure failed!', 'error')
      }
    },

    prepareAlert(message, type) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    info(item) {
      this.structure = this.getById(['data', item._uuid])
      this.$refs.info.openDialog()
    },

    async loadScopeData() {
      if (this.schemas.length === 0) {
        await this.load(['schema'])
      }
      if (this.$route.name === 'all-structures') {
        await this.load(['data'])
      } else {
        let schemas = this.schemas.filter(
          (e) => e.label === this.schemaLabelFromRouteName
        )
        await this.loadMultipleDataSets(schemas)
      }
      if (this.workflows.length === 0) {
        await this.load(['workflow'])
      }
      if (this.processes.length === 0) {
        await this.load(['process'])
      }
    },
  },

  computed: {
    ...mapGetters('backend', ['getStructureTable', 'getById', 'getDatasByTag']),

    ...mapState('backend', {
      schemas: (state) => state.schemas,
      workflows: (state) => state.workflows,
      processes: (state) => state.processes,
      alertTimeout: (state) => state.alertTimeout,
    }),

    getTableData() {
      if (this.$route.name === 'all-structures') {
        return this.getStructureTable('all')
      } else {
        return this.getStructureTable(this.schemaLabelFromRouteName)
      }
    },

    headers() {
      return [
        { text: 'Label', align: 'start', value: 'label' },
        { text: 'Products', value: 'products', sortable: false },
        { text: 'Description', align: 'start', value: 'description' },
        { text: 'Created by', value: 'source', sortable: false },
        { text: 'Date', value: 'createdDate' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
        { text: '', value: 'data-table-expand' },
      ]
    },

    schemaLabelFromRouteName(){
        return utils.getLabelFromPathName(this.$route.name )
    },
  },

  async created() {
    this.loadScopeData()
  },
}
</script>

<style scoped>
.alert {
  margin: auto;
}
</style>
